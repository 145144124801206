import styled from "@emotion/styled";
import mq from "@styles/mq";
import { GridColumn } from "semantic-ui-react";

export const FilterableHtmlContentBlockFilter = styled.h6`
    ${mq({
        fontSize:['18px','18px','20px'],
        lineHeight: ['26px','26px','28px'],
        letterSpacing: ['-0.44px','-0.44px','-0.44px']
    })}    
    margin: 10px 0;
    cursor: pointer; 
    font-weight: normal;   
    
    &.active {
        cursor: normal;
        font-weight: bold;
        text-decoration: underline;
    }
`

export const FilterableHtmlContentBlockTitle = styled(FilterableHtmlContentBlockFilter)`
    font-weight: bold;
`

export const FilterableHtmlContentBlockFilterContainer = styled(GridColumn)`
${mq({
    marginBottom: ['40px !important','0','0']
})}    
`